<template>

  <div id="bottom_fixed_bar" >
    <div class="row " v-if="shortcuts.length > 0">
      <div class="col-3" v-for="item in shortcuts">
        <router-link :to="item.link">
          <div class="text-center">
            <img :src="item.img" :alt="item.name" class="img-fluid" style="height: 28px;">
            <small>{{ item.name }}</small>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12 text-center">
        <router-link to="/admin/user/settings">
          Nastaviť rýchle skratky
        </router-link>
      </div>
    </div>
  </div>

</template>
<script>
import authMixin from "@/mixins/authMixin";
import SettingMixin from "@/mixins/settingMixin";
import jsonShortcuts from "@/lib/shortcuts.json";

export default {
  name: "bottom_actions",
  mixins: [authMixin, SettingMixin],
  computed: {
    shortcuts: function () {
      const jsonData = jsonShortcuts;
      const savedItems = this.storedSettings();
      const items = [];
      for (const key in jsonData) {
        const item = JSON.parse(JSON.stringify(jsonData[key]));
        if (Array.isArray(savedItems.bottom_shortcuts) && savedItems.bottom_shortcuts.includes(key)) {
          item.img = require(`@/assets/img/${item.icon}`);
          items.push(item);
        }
      }
      return items;
    }
  }
}
</script>

<style scoped lang="scss">
#bottom_fixed_bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #f3ebd4;
  border-top: 4px solid #ffdf00;
  height: 60px;
  padding-top: 7px;

  img {
    margin-bottom: 1px;
  }

  small {
    display: block;
    font-size: 8px;
    font-weight: bold;
    color: black;
    line-height: 8px;
  }
}
</style>
