
import Navbar from "@/components/navbar.vue";
import Bottom_actions from "@/components/bottom_actions.vue";

export default {
  name: "app",
  components: {
    Bottom_actions,
    Navbar
  }
}
